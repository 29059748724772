import React from 'react'

const TextArea = ({
  htmlFor,
  label,
  placeholder,
  rows,
  onChange,
} : {
  htmlFor: string,
  label: string,
  placeholder: string,
  rows: number,
  onChange: (value: string) => void
}) => {
  return (
    <div className="w-full mt-2">
      <label htmlFor={htmlFor} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
      <textarea
        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
        id={htmlFor}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        rows={rows}
        ></textarea>
    </div>

  )
}

export default TextArea