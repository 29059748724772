import React from 'react';
// import Logo from 'assets/imgs/transparent-logo.png';
import { Formik, Form, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { ThunkDispatch } from '@reduxjs/toolkit';
import Input from '../../components/Input';
import { create } from '../../redux/department/departmentSlice';
import MainLayout from '../../layout/MainLayout';
import Button from '../../components/Button';
import { useQuery } from '@tanstack/react-query';
import { getLocations } from '../../api';
import Select from '../../components/Select';


const DepartmentCreate = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const state = useSelector((state: any) => state);
  const initialValues: {
    name: string,
    location: string
  } = {
    name: '',
    location: ''
  }

  const { data: locations } = useQuery({
    queryFn: () => getLocations(state.auth.user.token),
    queryKey: ['locations']
  });

  const validator = object({
    name: string()
      .required('Department name is a required field.'),
  });

  const onSubmit =
    (values: {
      name: string,
      location: string
    }, actions: FormikHelpers<{
      name: string,
      location: string
    }>) =>
      dispatch(create(Object.assign(values, { token: state.auth.user.token })))
        .then((res) => res.type.includes('fulfilled') && actions.resetForm())

  const findLocation = (array: [], id: string): { id: string, name: string } =>
    array?.find((location: { id: string }) => location?.id === id) ?? ({ id: '', name: '' });

  return (
    <MainLayout>
      <div className="flex min-h-full flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {/* <img className="mx-auto h-20 w-auto" src={Logo} alt="Your Company" /> */}
          <h1 className="text-center text-2xl font-bold leading-9 tracking-tight text-dark-blue">Create Department</h1>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Formik
            initialValues={initialValues}
            validationSchema={validator}
            onSubmit={onSubmit}
          >
            {
              ({ values }) => (
                <Form className="space-y-6">

                  <Input name="name" label="Department Name" type="text" />

                  <Select
                    name="location"
                    options={locations?.map((location: { name: string, id: string }) => ({ value: location.id, label: location.name }))}
                    label="Select a Location"
                    value={values.location ? { label: findLocation(locations, values.location).name, value: findLocation(locations, values.location).id } : null}/>

                  <div>
                    <Button disabled={state.department.isLoading} type="submit">Create</Button>
                  </div>
                </Form>
              )
            }
          </Formik>
        </div>
      </div>
    </MainLayout>
  );
};

export default DepartmentCreate;
