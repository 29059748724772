import React from 'react'

const Button = ({
  colors = 'bg-green-600 hover:bg-green-400',
  disabled,
  label,
  type,
  size = 'medium',
  children,
  onClick,
}: {
  label?: string,
  children?: React.ReactNode,
  type: "button" | "submit" | "reset" | undefined,
  colors?: string,
  disabled?: boolean,
  size?: "small" | "medium" | "large",
  onClick?: () => void
}) => {
  const buttonSize = size === 'small' ?
    'text-xs px-1.5 py-1.5' :
    size === 'medium' ?
      'text-sm px-3 py-2' :
      'text-base py-5 py-2.5';

  return (
    <button
      onClick={() => onClick && onClick()}
      disabled={disabled}
      type={type}
      className={`${colors} flex w-full justify-center rounded-md ${buttonSize} font-medium leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${disabled ? 'cursor-not-allowed' : ''}`}>
      {label || children}
    </button>

  )
}

export const IconButton = ({
  children,
  color = 'green',
  disabled,
  size,
  type = 'button',
  onClick
}: {
  children: React.ReactNode
  color?: string
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
}) => {
  const btnColors = 'text-blue-700 border border-blue-700 hover:bg-blue-700 focus:ring-blue-300 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500'.replace('blue', color);

  const buttonSize = size === 'small' ?
    'text-xs p-1.5' :
    size === 'medium' ?
      'text-sm p-2' :
      'text-base p-2.5';

  return (
    <button
      type={type}
      className={`${btnColors} hover:text-white focus:ring-4 focus:outline-none font-medium rounded-full text-sm p-2.5${buttonSize} text-center inline-flex items-center ${disabled ? 'cursor-not-allowed' : ''}`}
      disabled={disabled}
      onClick={onClick}>
      { children }
      <span className="sr-only">Icon description</span>
    </button>
  )
}

export default Button