import React from 'react'
import { useField } from 'formik'
import TWSelect from 'react-tailwindcss-select';
import { SelectValue } from 'react-tailwindcss-select/dist/components/type';

const Select = ({
  label,
  name,
  options,
  value,
  isFlex, //If the parent container has flex properties
  flexClasses = 'mt-2 sm:w-full md:w-[48%] lg:w-[48%]'
} : {
  label: string,
  name: string,
  options: { label: string, value: string }[],
  value: { label: string, value: string } | null,
  isFlex?: boolean,
  flexClasses?: string
}) => {
  const [field, meta] = useField({ name });
  const className = isFlex ? flexClasses : 'mt-2 w-full';

  return (
    <div className={className}>
      <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
      <TWSelect isSearchable onChange={(value: SelectValue) => field.onChange({ type: '', target: { value: value && !Array.isArray(value) && value.value, name }})} value={value} options={options} primaryColor='blue' />
      <small className="text-sm mt-0.5 text-red-400">{meta.touched && meta.error}</small>
    </div>
  )
}

export default Select