import React from 'react'
import Picker from 'react-date-picker';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';

const DatePicker = ({
  children,
  maxDate,
  minDate,
  value,
  onChange,
} : {
  children?: React.ReactNode,
  maxDate?: Date,
  minDate?: Date | undefined,
  value: Date,
  onChange: (value: any) => void
}) => {
  return (
    <div>
      { children }
      <Picker
        onChange={onChange}
        maxDate={maxDate}
        minDate={minDate}
        value={value}
      />
    </div>
  )
}

export default DatePicker