import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { v4 } from 'uuid'
import { Menu, MenuLink as MenuLinkType, MenuLinkWithChildren as MenuLinkWithChildrenType } from '../types/menus'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../types/state'
import { logout } from '../redux/auth/authSlice'
import Badge from './Badge'
import { UserCircleIcon } from '@heroicons/react/24/solid'
import ClickAwayListener from 'react-click-away-listener'

const Navbar = () => {
  const menus: Menu = [
    {
      label: 'Home',
      to: '/'
    },
    {
      label: 'Users',
      links: [
        {
          label: 'Create',
          to: '/users/create',
          desc: 'Create a user'
        }

      ]
    },
    {
      label: 'Requisition',
      links: [
        {
          label: 'Create',
          to: '/requisitions/create',
          desc: 'Create a requisition'
        },
        {
          label: 'View',
          to: '/requisitions/list',
          desc: 'View requisitions'
        }
      ]
    },
    {
      label: 'Vehicles',
      links: [
        {
          label: 'Create',
          to: '/vehicles/create',
          desc: 'Create a Vehicle'
        }

      ]
    },
    {
      label: 'Pumps',
      links: [
        {
          label: 'Create',
          to: '/pump/create',
          desc: 'Create a Pump'
        },
        {
          label: 'List',
          to: '/pump/list',
          desc: 'List and Update Pumps'
        },
        {
          label: 'Purchase',
          to: '/pump/fuel-purchase',
          desc: 'Purchase Fuel'
        }
      ]
    },
    {
      label: 'Location',
      links: [
        {
          label: 'Create',
          to: '/location/create',
          desc: 'Create a Location'
        },
      ]
    },
    {
      label: 'Department',
      links: [
        {
          label: 'Create',
          to: '/department/create',
          desc: 'Create a Department'
        },
      ]
    },
  ];

  const auth = useSelector((state: State) => state.auth);
  const dispatch = useDispatch();

  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);

  const toggleMobileMenu = () => setIsMobileActive((prev) => !prev);

  const dispatchLogout = () => dispatch(logout());

  return (
    <header className="bg-white">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to='/' className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            {/* <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt=""/> */}
            <span>Logo</span>
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button onClick={toggleMobileMenu} type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
            <span className="sr-only">Open main menu</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          <div className="flex gap-2 items-center">
            <UserCircleIcon className='text-blue-500' width={28} height={28} />
            <Badge text={`${auth.user.username}`} />
          </div>
          {
            menus.map(menu => menu?.links ? <MenuLinkWithChildren key={v4()} links={menu.links} label={menu.label} /> : <MenuLink key={v4()} label={menu.label} to={menu.to || ''} />)
          }
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {
            auth.isLoggedIn ?
              <Link onClick={dispatchLogout} to="/auth/sign-in" className="text-sm font-semibold leading-6 text-gray-900">Log Out <span aria-hidden="true">&rarr;</span></Link> :
              <Link to="/auth/sign-in" className="text-sm font-semibold leading-6 text-gray-900">Log in <span aria-hidden="true">&rarr;</span></Link>
          }
        </div>
      </nav>
      {/* <!-- Mobile menu, show/hide based on menu open state. --> */}
      <div className={`lg:hidden ${isMobileActive ? '' : 'hidden'}`} role="dialog" aria-modal="true">
        {/* <!-- Background backdrop, show/hide based on slide-over state. --> */}
        <div className="fixed inset-0 z-10"></div>
        <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to='/' className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company Name</span>
              <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" />
            </Link>
            <button onClick={toggleMobileMenu} type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700">
              <span className="sr-only">Close menu</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <div className="flex gap-2 items-center">
                  <UserCircleIcon className='text-blue-500' width={28} height={28} />
                  <Badge text={`${auth.user.username}`} />
                </div>
                {
                  menus.map(menu => menu?.links ? <MobileMenuLinkWithChildren key={v4()} links={menu.links} label={menu.label} /> : <MobileMenuLink key={v4()} label={menu.label} to={menu.to || ''} />)
                }
              </div>
              <div className="py-6">
                {
                  auth.isLoggedIn ?
                    <Link onClick={dispatchLogout} to="/auth/sign-in" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log Out </Link> :
                    <Link to="/auth/sign-in" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log in </Link>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

  )
}

const MenuLink = ({ to, label }: MenuLinkType) => <Link to={to} className="text-sm font-semibold leading-6 text-gray-900">{label}</Link>

const MenuLinkWithChildren = ({
  label,
  links
}: MenuLinkWithChildrenType) => {
  const [isActive, setIsActive] = useState<{
    label: string
    open: boolean
  }>({
    label,
    open: false
  });
  // const [isMenuEntered, setIsMenuEntered] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const toggleSubmenus = () => setIsActive((prev) => ({
    ...prev,
    open: !prev.open
  }));

  // const onMenuEntered = () => {
  //   setIsMenuEntered(true);
  // };

  return (
    <ClickAwayListener onClickAway={() => {
      if (isActive.label === label) setIsActive((prev) => ({
        ...prev,
        open: false
      }))
    }}>
      <div className="relative">
        <button ref={buttonRef} onClick={toggleSubmenus} type="button" className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900" aria-expanded="false">
          {label}
          <svg className="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
          </svg>
        </button>
        <div className={`absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 ${isActive.open ? '' : 'hidden'}`}>
          <div className="p-4">
            {
              links.map((link) => <MenuLinkChild key={v4()} label={link.label} to={link.to} desc={link?.desc} />)
            }
          </div>
        </div>
      </div>
    </ClickAwayListener>
  )
}

const MenuLinkChild = ({ label, to, desc }: MenuLinkType) => (
  <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
    {/* <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
    <svg className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
      <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
    </svg>
  </div> */}
    <div className="flex-auto">
      <Link to={to} className="block font-semibold text-gray-900">
        {label}
        <span className="absolute inset-0"></span>
      </Link>
      {desc && <p className="mt-1 text-gray-600">{desc}</p>}
    </div>
  </div>
)

const MobileMenuLink = ({ to, label }: MenuLinkType) => <Link to={to} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{label}</Link>

const MobileMenuLinkWithChildren = ({
  label,
  links
}: MenuLinkWithChildrenType) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const toggleSubmenus = () => setIsActive((prev) => !prev);

  return (
    <div className="-mx-3">
      <button onClick={toggleSubmenus} type="button" className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" aria-controls="disclosure-1" aria-expanded="false">
        {label}
        <svg className="h-5 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
        </svg>
      </button>
      {/* <!-- 'Product' sub-menu, show/hide based on menu state. --> */}
      <div className={`mt-2 space-y-2 ${isActive ? '' : 'hidden'}`} id="disclosure-1">
        {links.map(link => <MobileMenuLinkChild key={v4()} to={link.to} label={link.label} />)}
      </div>
    </div>
  )
}

const MobileMenuLinkChild = ({ label, to, desc }: MenuLinkType) => (
  <Link to={to} className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">{label}</Link>
)

export default Navbar